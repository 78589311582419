const th = {
  network: {
    tips: `เครือข่ายที่เลือกไม่ได้รับการสนับสนุนในปัจจุบัน!
      กรุณาเลือกเครือข่าย Matic (Polygon)!`,
    ev: `โปรดตรวจสอบว่าคุณได้ติดตั้งซอฟต์แวร์กระเป๋าเงินอย่าง MetaMask, 
    Trust Wallet, imToken, หรือ TokenPocket หรือไม่`,
    copyight: "FlashDai",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `ข้อควรทราบสำหรับนักลงทุน: แอปพลิเคชันที่กระจายอำนาจ (DApp) นี้มีความเสี่ยงในการลงทุนที่ไม่แน่นอน นโยบายและกฎระเบียบของแต่ละประเทศอาจมีการควบคุมและจำกัดเกี่ยวกับเทคโนโลยีบล็อกเชนและการลงทุนที่เกี่ยวข้องอย่างเข้มงวด นอกจากนี้ยังมีความผันผวนสูงของราคาสกุลเงินดิจิทัล ทำให้นักลงทุนต้องเผชิญกับความเสี่ยงในการลงทุนที่มาก นักลงทุนควรทำความเข้าใจและประเมินความเสี่ยงในการลงทุนให้ดีก่อนที่จะลงทุน และต้องตัดสินใจอย่างระมัดระวัง พวกเราขอแนะนำให้นักลงทุนลงทุนอย่างมีเหตุผลและไม่ตามกระแส 
    จึงจะหลีกเลี่ยงการสูญเสียทางเศรษฐกิจที่ไม่สามารถกู้คืนได้`,
    protocol: `แอปพลิเคชันที่กระจายอำนาจ (DApp) นี้มีความเสี่ยงในการลงทุนที่ไม่แน่นอน ก่อนใช้งาน โปรดอ่านและทำความเข้าใจในคำเตือนเกี่ยวกับความเสี่ยงและนโยบายความเป็นส่วนตัวอย่างรอบคอบ`,
    more: "รายละเอียด",
    media: "โซเชียลมีเดียที่เกี่ยวข้อง",
    stake:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    withdraw:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    Reinvest:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    Getreward:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    linkwallet: "เชื่อมต่อกระเป๋าเงิน",
    notification: "ฟีเจอร์นี้กำลังจะเปิดตัวเร็วๆ นี้! ติดตามการแจ้งเตือนจากชุมชน",
    flashloansOK:'เริ่มต้นสำเร็จ!',
    flashloansNo:'เริ่มต้นไม่สำเร็จ',
    flashloansOnce:'ขอแสดงความยินดีที่คุณได้รับโอกาสทดลอง 1 ครั้ง',
    flashloansError:'ขออภัย, โอกาสในการทดลองได้ถูกใช้ไปแล้ว',
    falocnerror:'Falcon™ V2 ไม่ได้รับอนุญาตให้ทดสอบ!',
    falocninfo:'Falcon™ V2 สำหรับผู้เล่นที่ได้รับการอนุมัติระดับ V3! ขณะนี้อยู่ในช่วงทดสอบสาธารณะ!',
    falconv4:'คลัสเตอร์เก็งกำไร Falcon™V4 กำลังอยู่ในช่วงทดสอบเบต้า (28000 ครั้ง/วัน)!'
  },
  leavel: {
    0:"เมล็ด(Mel̆d)",
    1: "แบล็คเคอร์แรนท์",
    2: "เชอร์รี",
    3: "แก้วมังกร",
    4: "เฮเซิลนัท",
    5: "โนนิ",
    6: "ลูกพลับ",
    7: "ทรัฟเฟิล",
  },
  top: {
    Account: "บัญชี",
  },
  swap:{
    Swap:"แลกเปลี่ยน",
    paynameA:"การชำระเงิน",
    paynameB:"การรับเงิน",
    Approve:"การอนุญาต",
    launchSwap:"เริ่มการแลกเปลี่ยน",
    recommend:"การแนะนำ",
    swapError:"ข้อผิดพลาดในการแลกเปลี่ยน",
    swapNetwork:"ข้อผิดพลาดของเครือข่าย",
  },
  stake: {
    Balance: "ยอดคงเหลือของคุณ",
    Total: "เดิมพันทั้งหมด",
    Appoove: "อนุมัติ",
    Stake: "เดิมพัน",
    Appooveing: "กำลังอนุมัติ",
    AuthorSuccessful: "อนุมัติสำเร็จ",
    AuthorFailed: "อนุมัติไม่สำเร็จ",
    Stakeing: "กำลังฝาก",
    StakeSuccessful: "ฝากสำเร็จ",
    StakeFailed: "ฝากไม่สำเร็จ",
    StakeInfo: "ไม่สามารถน้อยกว่า:",
  },
  menu: {
    Home: "หน้าแรก",
    Language: "ภาษา",
    Document: "หนังสือขาว",
    Falcon:"Falcon™",
    Contract: "สัญญา",
    Watch: "ดู",
    Coming: "เร็ว ๆ นี้",
    EvDao: "BULLDAO",
    Flashloan: "เปิดตัว Flashloan",
    Developer:"เอกสารนักพัฒนา",
    Launch:"Launch",
    xNumber:"ที่เหลือ",
    xTips:"การประมวลผลอัตโนมัติ",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    Market:"Market",
    LogiV3tips:"Falcon™ V3 เริ่มการทดสอบสาธารณะทั่วโลกแล้ว (คำขอต่อวัน 1000~7000 ครั้ง) ต้องได้รับอนุญาตสำหรับสัญญาอาร์บิทราจที่สอดคล้องกัน!",
    Appoove2:"Auto",
        Ido:"Falcon IDO",
    myIdo:"My Falcon",
    interest:"กำไร",
  },
  ido:{
    select: "Select Community",
    FlashDai: "FlashDai",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "Other",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"

  },
  Withdraw: {
    Withdraw: "ถอน",
    Withdrawable: "สามารถถอนได้",
    Earnings: "รายได้",
    Brokerage: "จำนวนค่านายหน้า",
    Reinvest: "ลงทุนใหม่",
    Getreward: "รับรางวัล",
    Processing: "กำลังดำเนินการ",
    unsuccessfule: "ไม่ประสบความสำเร็จ",
    successful: "ประสบความสำเร็จ!",
    WithdrawInfo1: "โปรดใส่จำนวนที่ต้องการถอน",
    WithdrawInfo2: "จำนวนไม่เพียงพอ",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "ทีมเดิมพัน",
    leavel: "ระดับ",
    Team: "ทีม",
    Bind: "ผูกความสัมพันธ์",
    info: "ระบบพักชั่วคราวการผูกข้อมูล โปรดรอให้เปิด！",
    Binding: "กำลังผูกมัด",
    BindingSuccessful: "ผูกมัดสำเร็จ",
    BindingFailed: "ผูกมัดไม่สำเร็จ",
    Bindinfo: "กรุณาใส่ที่อยู่กระเป๋าเงินของผู้เชิญ",
  },
  Share: {
    Invitelink: "ลิงก์เชิญ",
    Intips:
      "คำเชิญ Dapp ไม่สามารถใช้ได้ในปัจจุบัน โปรดรอการอัปเดตล่าสุดจากชุมชน",
    copyTitle: "ลิงค์เชิญ",
    copyText: "คัดลอกลิงค์เชิญสำเร็จแล้ว!",
    copyCommnad: "คัดลอกลิงค์",
  },
  Contract: {
    contractaddress: "ที่อยู่สัญญา",
    Copylink: "Polygonscan",
    CopylinkText: "ไปที่ polygonscan!",
  },
  Wathch: {
    watchonlywallet: "กระเป๋าสตางค์ดูเท่านั้น",
    addwallet: "เพิ่มกระเป๋าสตางค์ดูเท่านั้น",
    add: "เพิ่ม",
    Leavel: "ระดับ",
    share: "แบ่งปัน",
    fnOpen: "ยังไม่ได้เชิญกระเป๋าเงินนี้เข้าร่วมฟีเจอร์นี้!",
    nullString: "กรุณาใส่ที่อยู่กระเป๋าเงิน！",
    addok:"เพิ่มสำเร็จ",
    addError:"เพิ่มไม่สำเร็จ",
    addisExists:"ที่อยู่นี้มีอยู่แล้ว",
    addlist:"รายการที่ติดตาม",
  },
};
export default th;
