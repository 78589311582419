<template>
  <div id="HomePage">
    <div id="TopHead">
      <div id="hx">
        <div class="topPart">
          <div class="miniLogo">
            <router-link to="/EvStake"><img :src="Evfinance" /></router-link>
          </div>
          <div>
            <img class="CurrentNetwork" :src="groupMatic" />
            <img @click="value2 = true" class="MoreSetup" :src="rightMenu" />
          </div>
        </div>
        <div class="accountInfo">
          <div class="accTips">{{ $t("top.Account") }}</div>
          <div class="currWallet">
            {{ shortenedAddress }}
          </div>
        </div>
      </div>
    </div>

    <div id="bodymain">
      <div class="tempContent">
        <router-view></router-view>
        <div class="footer">
          <div class="media">
            <ul>
              <li>
                <a href="https://x.com/theFlashDai" target="_blank" rel="noopener noreferrer">
                  <img class="img1" :src="twiter" alt="" /></a>
              </li>
              <li>
                <a href="https://t.me/theFlashDai" target="_blank" rel="noopener noreferrer"><img class="img4"
                    :src="fackbook" alt="" /></a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61552233524941&mibextid=LQQJ4d" target="_blank"
                  rel="noopener noreferrer">
                  <img class="img5" :src="fa" alt="" /></a>
              </li>
              <li>
                <a href="https://discord.gg/vd4hPpmvaY" target="_blank" rel="noopener noreferrer">
                  <img class="img6" :src="discord" alt="" /></a>
              </li>
              <li>
                <a href="https://github.com/BullDeveloper2022/flashmoney" target="_blank" rel="noopener noreferrer">
                  <img class="img2" :src="github" alt="" /></a>
              </li>
              <!-- // <li><img class="img3" :src="discord" alt=""></li> -->
            </ul>
          </div>
          <div class="author">
            {{ $t("network.copyight") }}
          </div>
        </div>
        <div class="SupportEmail">
          <strong>*</strong> {{ $t("network.protocol") }}
          <router-link to="/EvPrivate">{{ $t("network.more") }}</router-link>
        </div>
      </div>
    </div>

    <Drawer placement="right" :closable="false" v-model="value2" style="background-color: aqua">
      <div class="menuTop">
        <div class="evwhitelogo"><img :src="evwhite" /></div>
        <div class="provider">FlashDai</div>
        <div class="productsName">Flashloan</div>
      </div>
      <Divider style="background: #c7bebe" />
      <div class="menuList">
        <ul>
          <li>
            <router-link to="/EvStake"><img :src="EvHome" />{{ $t("menu.Home") }}</router-link>
          </li>
          <li>
            <router-link to="/EvContract"><img :src="EvCon" />{{ $t("menu.Contract") }}</router-link>
          </li>
          <li>
            <a href="https://ev2-cross-finance.gitbook.io/en/" target="_blank" rel="noopener noreferrer"><img
                :src="EvDoc" />{{ $t("menu.Document") }}</a>
          </li>
          <li @click="isLanguageMenuOpen = !isLanguageMenuOpen">
            <router-link to=""><img :src="EvLang" />{{ $t("menu.Language") }}
            </router-link>

            <ul :class="{ open: isLanguageMenuOpen }">
              <li @click="changeLanguageB('en')">English</li>
              <li @click="changeLanguageB('de')">Deutsch</li>
              <li @click="changeLanguageB('vi')">Việt Nam</li>
              <li @click="changeLanguageB('zhTW')">中文繁體</li>
              <li @click="changeLanguageB('ja')">日本語</li>
              <li @click="changeLanguageB('ko')">한국어</li>
              <li @click="changeLanguageB('pt')">Portugal</li>
              <li @click="changeLanguageB('th')">ภาษาไทย</li>
              <li @click="changeLanguageB('ru')">русский язык</li>
              <li @click="changeLanguageB('mn')">Монгол хэл</li>
            </ul>
            <img :src="arrowDown" class="arrD" />
          </li>
          <li>
            <router-link to="/FlashLoans"><img :src="FG1" />{{ $t("menu.Flashloan") }}</router-link>
          </li>

          <li @click="isFalcon = !isFalcon">
            <router-link to=""><img :src="falocn" />{{ $t("menu.Falcon") }}
            </router-link>

            <ul :class="{ open: isFalcon }">
              <!-- <li>
                <router-link to="/EvFalocn">{{ $t("menu.LogiV2") }}</router-link>
              </li> -->
              <li>
                <router-link to="/EvFalocn2pro">{{ $t("menu.LogiV2pro") }}</router-link>
              </li>
              <li>
                <router-link to="/EvFalocnV3">{{ $t("menu.LogiV3") }}</router-link>
              </li>
              <!-- <li>
                <router-link to="/FalconIdo">{{ $t("menu.Ido") }}</router-link>
              </li> -->

              <li>
                <router-link to="/FalconIdo2">{{ $t("menu.myIdo") }}</router-link>
              </li>
            </ul>

            <img :src="arrowDown" class="arrD" />

          </li>


          <li>
            <router-link to="/EvSwap"><img :src="swap" />{{ $t("menu.swap") }}</router-link>
          </li>
          <li>
            <a href="https://www.bulldeveloper.net/#governance" target="_blank" rel="noopener noreferrer"><img
                :src="bullDAO" />{{
                  $t("menu.EvDao") }}</a>
          </li>
        </ul>
      </div>

      <Divider><span style="color: white">{{ $t("menu.Coming") }}</span></Divider>

      <div class="menuList">
        <ul>
          <li>
            <img :src="falocn" /><router-link to="/EvFalocnV4">{{ $t("menu.LogiV4") }}</router-link>
          </li>


          <li>
            <router-link to=""><img :src="market" />{{ $t("menu.Market") }}</router-link>
          </li>



        </ul>
      </div>
    </Drawer>
  </div>
</template>

<script>
import web3Utils from "@/utils/web3Utils.js";
import RightMenu from "@/assets/img/RightMenu.svg";
import evwhite from "@/assets/img/evwhite.svg";
import MiniLogo from "@/assets/img/MiniLogo.svg";
import GroupMatic from "@/assets/img/GroupMatic.svg";
import Evfinance from "@/assets/img/Evfinance.svg";
import Facebook from "@/assets/img/t.svg";
import discord from "@/assets/img/ds.svg";
import Fa from "@/assets/img/fa.svg"; //facebook
import github from "@/assets/img/git.svg";
import twiter from "@/assets/img/tw.svg";
import EvHome from "@/assets/img/EvHome.svg";
import EvCon from "@/assets/img/EvCon.svg";
import EvDoc from "@/assets/img/EvDoc.svg";
import EvLang from "@/assets/img/EvLang.svg";
import EvDao from "@/assets/img/EvDao.svg";
import EvWatch from "@/assets/img/EvWatch.svg";
import FG1 from "@/assets/img/FG1.svg";
import FG2 from "@/assets/img/FG2.svg";
import falocn from "@/assets/img/FalconLogoNew.svg";
import swap from "@/assets/img/swap.svg";
import market from "@/assets/img/market.svg";
import bullDAO from "@/assets/img/bullDAO.svg";
import arrowDown from "@/assets/img/arrowDown.svg";

export default {
  name: "App",
  components: {},
  async mounted() {
    var Address = await web3Utils.connectWallet();
    if (Address) {
      this.daiBalance = await web3Utils.getDaiBalance(Address);
    }
    this.currentAddress = Address;
    //   var observeAddress= window.localStorage.getItem('observeAddress')
    //  if(observeAddress){
    //   this.observeshow=true
    //   this.currentAddress=observeAddress
    //  }else{
    //   this.observeshow=false
    //   this.currentAddress=Address
    //  }
  },
  methods: {
    changeLanguageB(val) {
      const selectedLang = val;
      this.$store.commit("SET_LANGUAGE", selectedLang); // Assuming you have SET_LANGUAGE mutation in your Vuex store
      this.$i18n.locale = selectedLang;
    },
    onreturn() {
      window.localStorage.setItem("observeAddress", "");
      location.reload();
    },
  },
  computed: {
    shortenedAddress: {
      get() {
        if (this.currentAddress === "") {
          return "";
        } else {
          return (
            this.currentAddress.slice(0, 4) +
            "···" +
            this.currentAddress.slice(-4)
          );
        }
      },
      set(newVal) {
        this.currentAddress = newVal;
      },
    },
  },
  data() {
    return {
      isLanguageMenuOpen: false,
      isFalcon: false,
      EvWatch: EvWatch,
      EvHome: EvHome,
      EvCon: EvCon,
      EvDoc: EvDoc,
      EvLang: EvLang,
      EvDao: EvDao,
      evwhite: evwhite,
      twiter: twiter,
      github: github,
      arrowDown: arrowDown,
      fa: Fa,
      FG1: FG1,
      FG2: FG2,
      swap: swap,
      bullDAO: bullDAO,
      market: market,
      falocn: falocn,
      discord: discord,
      fackbook: Facebook,
      rightMenu: RightMenu,
      miniLogo: MiniLogo,
      groupMatic: GroupMatic,
      Evfinance: Evfinance,
      value2: false,
      selectedLanguage: this.$i18n.locale,
      currentAddress: "",
      daiBalance: 0,
      isPolygonChain: false,
      observeshow: false,
    };
  },
};
</script>

<style lang="less" scoped>
.seleL {
  color: red !important;
}

.menuList {
  ul {
    list-style: none;

    li {
      height: 40px;
      position: relative;

      ul {
        transform: translateY(-100%);
        opacity: 0;
        transition: transform 0.3s, opacity 0.3s;
      }

      .arrD {
        width: 10px;
        height: 5px;
        position: relative;
        top: -3px;
        left: 10px;
      }

      ul.open {
        display: block;
        animation: slideDown 2s forwards;
      }

      @keyframes slideDown {
        from {
          transform: translateY(-10%);
          opacity: 0;
        }

        to {
          transform: translateY(0);
          opacity: 1;
        }
      }

      ul {
        display: none;
        position: absolute;
        width: 100%;
        left: 0px;
        top: 36px;
        z-index: 1000;

        li {
          background-color: #2a94e5;
          color: white;
          padding: 10px;
          cursor: pointer;

          &:hover {
            background-color: #be0289;
          }
        }
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        vertical-align: middle;
      }

      a {
        color: white;
        font-size: 15px;
        font-family: "Poppins", sans-serif;
      }
    }
  }
}

.menuTop {
  display: flex;
  flex-direction: column;

  .evwhitelogo {
    text-align: center;

    img {
      width: 39px;
      height: 39px;
    }
  }

  .provider {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 15px 0 4px 0;
    font-family: "Poppins", sans-serif;
  }

  .productsName {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
}

/deep/ .ivu-drawer-content {
  background: linear-gradient(to bottom, #02213d, #be0289);
}

.SupportEmail {
  text-align: left;
  font-size: 10px;
  line-height: 14px;
  font-family: "Poppins", sans-serif;
  color: rgb(130, 121, 121);
  padding: 0px 0 4px 0;

  a {
    color: rgb(250, 247, 247);
    font-weight: bold;
    text-decoration: underline;
  }
}

.footer {
  margin-top: 20px;
  bottom: 20px;
  height: 50px;
  color: white;
  line-height: 50px;
  display: flex;

  .author {
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: bold;
    position: relative;
    top: -4px;
    color: #045da1;

    &:after {
      content: "FlashDai";
      color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 0; // 铺满整个元素
      bottom: 0; // 铺满整个元素

      -webkit-clip-path: ellipse(100px 100px at 50% 50%);
      clip-path: ellipse(100px 100px at 0% 50%);
      animation: spotlightMove 5s infinite alternate; // 添加动画
      background-image: url("@/assets/img/light.png");
      background-size: 200%;
      background-position: center center;
      -webkit-background-clip: text;
      background-clip: text;
    }

    @keyframes spotlightMove {
      0% {
        -webkit-clip-path: ellipse(100px 100px at 0% 50%);
        clip-path: ellipse(100px 100px at 0% 50%);
      }

      100% {
        -webkit-clip-path: ellipse(20px 50px at 100% 50%);
        clip-path: ellipse(20px 50px at 100% 50%);
      }
    }
  }

  .media {
    flex: 1;

    ul {
      display: flex;
      list-style: none;

      li {
        margin-right: 10px;

        img.img1,
        img.img2,
        img.img4 {
          width: 20px;
          height: 25px0px;
        }

        img.img3 {
          width: 21px;
          height: 16px;
        }

        // facebook
        img.img5 {
          width: 19.92px;
          height: 19.92px;
        }

        // discord
        img.img6 {
          width: 21px;
          height: 16px;
          position: relative;
          top: -2px;
        }
      }
    }
  }
}

#HomePage {
  background-color: black;
  width: 100vw;
  height: 100vh;

  .tempContent {
    width: 330px;
    padding: 10px 0;
    margin: 0 auto;
  }

  .nav {
    width: 100%;
    // padding: 10px 0;
    border-radius: 6px;
    margin: 0 auto;
    // background: linear-gradient(to bottom, #00050a 40%, hsla(207, 4%, 48%, 0.4));

    ul {
      width: 330px;
      margin: 0 auto;
      list-style: none;
      display: flex;
      justify-content: space-around;

      li {
        flex: 1;

        a {
          color: rgb(128, 131, 131);
          font-family: "Poppins", sans-serif;
          padding: 8px;
          border-radius: 0px;
          background: #120245;
          border: 2px solid #05080b;
          display: block;
          text-align: center;
        }

        a.router-link-active {
          color: aqua;
          font-family: "Poppins", sans-serif;
          padding: 8px;
          font-weight: 800;
          border-radius: 5px;
          background: linear-gradient(to bottom,
              #5e0463,
              hsla(208, 80%, 54%, 0.603));
          color: white;
          border: 2px solid #05080b;
          display: block;
        }
      }
    }
  }

  #TopHead {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: url("../assets/img/headBg.png") no-repeat center center/cover;
    color: white;
    padding: 10px;
    text-align: center;
    z-index: 1000;

    #hx {
      width: 330px;
      margin: 0 auto;
    }

    .accountInfo {
      display: flex;

      .accTips {
        flex: 1;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        color: white;
        position: relative;
        top: 5px;
        text-align: left;
      }

      .currWallet_type {
        width: 80px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        border: 2px solid transparent;
        background-image: linear-gradient(#000, #000),
          radial-gradient(circle at top left, #fff, #fff);
        background-origin: border-box;
        background-clip: content-box, border-box;
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        margin-right: 10px;
      }

      .currWallet {
        height: 30px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 2px solid #81c0f1;
        // background-image: linear-gradient(#000, #000),
        //   radial-gradient(circle at top left, #81c0f1, #fa36a6);
        background-origin: border-box;
        background-clip: content-box, border-box;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
      }

      .currWalletnull {
        height: 30px;
        background: linear-gradient(to right, #81c0f1, #fa36a6);
        border: none;
        color: white;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        transition: background 0.3s ease;

        &:hover {
          background: linear-gradient(to right, #fa36a6, #81c0f1);
        }
      }
    }

    .topPart {
      display: flex;

      .miniLogo {
        flex: 1;
        text-align: left;

        img {
          width: 36px;
          height: 36px;
          text-align: left;
        }
      }

      .CurrentNetwork {
        margin-right: 10px;
        position: relative;
        top: 6px;
        width: 32px;
        height: 25px;
      }

      .MoreSetup {
        cursor: pointer;
        width: 11px;
        height: 25px;
        position: relative;
        top: 6px;
      }
    }
  }

  #bodymain {
    margin-top: 90px;
    background-color: #000000;
    padding: 10px;
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
}
</style>
