const mn = {
    network: {
      tips: `Сонгосон сүлжээ одоогоор дэмжигдэхгүй байна!
      Polygon (Matic) сүлжээг сонгоно уу!'`,
      ev: "MetaMask, Trust Wallet, imToken, TokenPocket зэрэг хэтэвч програмууд суулгасан эсэхээ шалгана уу.",
      copyight: "FlashDai",
      all: "Хамгийн их",
      bolck: "Dai тогтвортой зоос",
      law: `Хөрөнгө оруулагчдын анхааралд: Энэ Түгээмэл Програм (DApp) нь тодорхой бус хөрөнгө оруулалтын эрсдэлтэй. Үндэсний бодлого болон зохицуулалтын хууль нь блокчэйн технологи болон холбогдох хөрөнгө оруулалтыг хатуу хянаж, хязгаарлах боломжтой. Мөн, дижитал валютын үнэ маш тогтворгүй бөгөөд хөрөнгө оруулагчид их хэмжээний хөрөнгө оруулалтын эрсдэл хүлээж болох юм. Хөрөнгө оруулалт хийхээсээ өмнө хөрөнгө оруулагчид эрсдэлийг бүрэн ойлгож, үнэлэх хэрэгтэй. Бид хөрөнгө оруулагчдад болгоомжтой, бодитойгоор хөрөнгө оруулахыг зөвлөж байна.`,
      protocol: `Энэ Түгээмэл Програм (DApp) нь тодорхой бус хөрөнгө оруулалтын эрсдэлтэй. Ашиглахаасаа өмнө эрсдэлтэй байдал болон нууцлалын бодлогыг уншиж, бүрэн ойлгох хэрэгтэй.`,
      more: "Илүү их",
      media: "Холбогдох Социал Медианууд",
      stake:
        "2023 оны 11 сарын 6-нд дэлхий даяар нээлттэй, Telegram болон Twitter дээр биднийг дагаарай!",
      withdraw:
        "2023 оны 11 сарын 6-нд дэлхий даяар нээлттэй, Telegram болон Twitter дээр биднийг дагаарай!",
      Reinvest:
        "2023 оны 11 сарын 6-нд дэлхий даяар нээлттэй, Telegram болон Twitter дээр биднийг дагаарай!",
      Getreward:
        "2023 оны 11 сарын 6-нд дэлхий даяар нээлттэй, Telegram болон Twitter дээр биднийг дагаарай!",
      linkwallet: "Хэтэвчээ холбох",
      notification: "Энэ функц удахгүй ирнэ! Нийтийн зарлалыг хүлээнэ үү.",
      flashloansOK:'Амжилттай эхэлсэн!',
      flashloansNo:'Эхлэх амжилтгүй болсон!',
      flashloansOnce:'Туршлага олж авах нэг боломжийг авлаа!',
      flashloansError:'Уучлаарай, туршлага олж авах боломж дууссан байна!',
      falocnerror:'Falcon™ V2 туршилтын эрх олгогдоогүй!',
      falocninfo:'Falcon™ V2 тоглогчдод V3 түвшинд батлагдсан! Одоогоор нийтийн туршилтад байна!',
      falconv4:'Falcon™V4 арбитражийн кластер бета туршилтад байна (өдөрт 28000 удаа)!'
    },
    leavel: {
      0:"Үр",
      1: "Хар жимс",
      2: "Интоор",
      3: "Луугийн жимс",
      4: "Самар",
      5: "Нони",
      6: "Хар чавга",
      7: "Гүнждийн үр",
    },
    ido:{
      select: "Олон нийтийг сонгох",
      FlashDai: "FlashDai",
      flashdragon: "FlashDrgon",
      fashFalcon: "Falcon",
      powermonger: "PowerMonger",
      fashfalcon2: "FalconV2",
      other: "Бусад",
      Getmax: "Хамгийн их авах",
      nowbuy: "Одоо захиалах",
      mynumber: "Миний Falcon",
      myrelease: "Гаргасан",
      waitrelease: "Гаргахыг хүлээж байна",
      get: "Татах",
      FalconF: "FlashFalcon(F)",
      FalconA: "FlashFalcon(A)",
      organization: "Байгууллага",
      endTimes:'Эхний үеийн тоолол',
      getmax:'Хамгийн их авах',
      Authorization:"Зөвшөөрөл",
      Subscribe:"Одоо захиалах",
      okSubscribe:"Захиалсан",
      message:"Уламжлал алдаа, Node-ийг өөрчлөнө үү",
      end1:"Эхний үе дууссан",
      needStart:"Эхлэхийн тоолол",
      getscusse:"Амжилттай авсан",
      geterror:"Амжилтгүй болсон"
    },
    top: {
      Account: "Данс",
    },
    stake: {
      Balance: "Таны баланс",
      Total: "Нийт хадгаламж",
      Appoove: "Зөвшөөрөх",
      Stake: "Хадгаламж",
      Appooveing: "Зөвшөөрч байна",
      AuthorSuccessful: "Зөвшөөрөл амжилттай",
      AuthorFailed: "Зөвшөөрөл амжилтгүй болсон",
      Stakeing: "Хадгалж байна",
      StakeSuccessful: "Хадгалалт амжилттай",
      StakeFailed: "Хадгалалт амжилтгүй болсон",
      StakeInfo: "Дараах хэмжээнээс бага байж болохгүй:",
    },
    swap:{
      Swap:"Солилцоо",
      paynameA:"Төлбөр",
      paynameB:"Хүлээн авах",
      Approve:"Зөвшөөрөх",
      launchSwap:"Солилцоог эхлүүлэх",
      recommend:"Зөвлөмж",
      swapError:"Солилцооны алдаа",
      swapNetwork:"Сүлжээний алдаа",
    },
    menu: {
      Home: "Нүүр хуудас",
      Language: "Хэл",
      Document: "Цагаан баримт",
      Contract: "Гэрээ",
      Falcon:"Falcon™",
      Watch: "Ажиглах",
      Coming: "Удахгүй ирнэ",
      EvDao: "Засаглал",
      Flashloan: "Эхлүүлэх",
      Developer:"Хөгжүүлэгчийн бичиг баримт",
      Launch:"Эхлүүлэх",
      xNumber:"Үлдэгдэл",
      xTips:"Автоматаар боловсруулах",
      LogiV2:"Falcon™ V2",
      LogiV3:"Falcon™ V3",
      LogiV4:"Falcon™ V4",
      swap:"Солилцоо",   
      LogiV2pro:"Falcon™ V2+",
      LogiV3tips:"Falcon™ V3 дэлхийн нийтийн туршилт эхэллээ (өдөрт 1000~7000 хүсэлт), харгалзах арбитражийн гэрээнүүдэд зөвшөөрөл шаардлагатай!",
      Appoove2:"Автомат",
      Market:"Зах зээл",
      Ido:"Эрт захиалгын үе 1",
      myIdo:"Миний Falcon",
      interest:"Ашиг",
    },
    Withdraw: {
      Withdraw: "Татах",
      Withdrawable: "Татах боломжтой",
      Earnings: "Орлого",
      Brokerage: "Брокерийн хэмжээ",
      Reinvest: "Дахин хөрөнгө оруулах",
      Getreward: "Шагналаа авах",
      Processing: "Боловсруулж байна",
      unsuccessfule: "Амжилтгүй болсон",
      successful: "Амжилттай!",
      WithdrawInfo1: "Татах хэмжээг оруулна уу",
      WithdrawInfo2: "Хангалтгүй хэмжээ",
    },
    Team: {
      innver: "Дотоод",
      Teamstaked: "Багийн хадгаламж",
      leavel: "Хүндэтгэл",
      Team: "Баг",
      Bind: "Холболт",
      info: "Систем түр зуурын холболтыг зогсоосон, нээхийг хүлээнэ үү.",
      Binding: "Холбож байна",
      BindingSuccessful: "Холболт амжилттай",
      BindingFailed: "Холболт амжилтгүй болсон",
      Bindinfo: "Уригчийн хэтэвчийн хаягийг оруулна уу",
    },
    Share: {
      Invitelink: "Урилгын холбоос",
      Intips: `Dapp урилгууд одоогоор боломжгүй байна. 
              Хамт олны хамгийн сүүлийн үеийн шинэчлэлтийг хүлээнэ үү.`,
      copyTitle: "Урилгын холбоос",
      copyText: "Урилгын холбоос амжилттай хуулсан!",
      copyCommnad: "Холбоосыг хуулах",
    },
    Contract:{
    contractaddress: "Гэрээний хаяг",
    Copylink: "Polygonscan",
    CopylinkText: "Polygonscan руу ороорой!",
    },
    Wathch: {
    watchonlywallet: "Зөвхөн ажиглах хэтэвч",
    addwallet: "Хэтэвч нэмэх",
    add: "эмэх",
    Leavel: "Хүндэтгэл",
    share: "Хуваалцах",
    fnOpen:
    "Энэ хэтэвч нь энэ функцэд оролцох урилгыг аваагүй байна!！",
    nullString: "Хэтэвчийн хаягийг оруулна уу!",
    addok:"Амжилттай нэмэгдсэн",
    addError:"Нэмэгдээгүй",
    addisExists:"Энэ хаяг аль хэдийн байна",
    addlist:"Ажиглалтын жагсаалт",
    },
    };
    export default mn;