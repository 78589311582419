<template>
  <div>
    <div class="stakewait">
      <div class="coinInfomation">
        <div class="coinInfo">{{ $t("Share.Invitelink") }}</div>
      </div>

      <Divider style="background: rgba(142, 200, 243, 0.1)" />

      <div class="inputInfoOk">
        <input v-model="shortenedAddress" type="text" class="inputStyle" />
      </div>

      <div class="Qrcode">
        <QrCodeGenerator :text="`https://flashdai.net/#/EvTeam?ref=${this.invAddress}`"></QrCodeGenerator>
      </div>
      <div class="StakeAuthor">
        <button class="StakeBtn2 ripple" v-clipboard:copy="linkAddress2" v-clipboard:success="onCopy"
          v-clipboard:error="onError">
          {{ $t("Share.copyCommnad") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.ripple {
  position: relative;
  overflow: hidden;
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: radial-gradient(circle, #666 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .3s, opacity .5s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}

.stakewait {
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0.4px rgb(80, 77, 77) inset;
  background-image: linear-gradient(133deg,
      rgba(7, 110, 150, 0.3),
      /* Adjust the last value (0.2) to set the opacity of the first color */
      rgba(132, 2, 72, 0.6));
  /* Adjust the last value (0.2) to set the opacity of the second color */
  box-sizing: border-box;
  width: 330px;
  color: white;
  padding: 40px 20px 20px 20px;

  .bStyle {
    width: 290px;
    height: 76px;
  }

  .Qrcode {
    margin: 0 auto;
    width: 134px;
    height: 134px;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .StakeAuthor {
    justify-content: space-between;

    .StakeBtn2 {
      width: 100%;
      height: 50px;
      line-height: 50px;
      margin-top: 20px;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 18px;
      color: rgb(2, 0, 0);
      font-size: 20px;
      background: url("../assets/img/Gradient.svg") no-repeat center center/cover;
    }

    .Author {
      width: 136px;
      height: 43px;
    }

    .Stakeing {
      width: 136px;
      height: 43px;
    }
  }

  .StakeInfo {
    display: flex;
    margin-bottom: 20px;

    .StakeResult {
      flex: 1;
      text-align: left;
      font-size: 14px;
      font-weight: 600;

      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 5px;
        margin-right: 10px;
      }
    }

    .StakeMoney {
      text-align: right;
      font-size: 14px;
      font-weight: 600;

      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 5px;
        margin-right: 10px;
      }

      .daimi {
        margin-left: 5px;
        width: 14px;
        height: 12px;
      }
    }
  }

  .inputInfo {
    border: 2px solid #81c0f1;
    height: 53px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;

    .tbnStyle {
      height: 34px;
      position: relative;
      color: white;
      top: 8px;
      right: 16px;
      border: none;
      outline: none;
      font-size: 20px;
      border-radius: 12px;
      padding: 0 10px;
      cursor: pointer;
      background: url("../assets/img/Gradient.svg") no-repeat center center/cover;
    }
  }

  .ownwalletInfo {
    font-family: "Poppins", sans-serif;
    display: flex;

    .ownTips {
      font-size: 18px;
      font-weight: 600;
      flex: 1;
    }

    .ownMoney {
      font-size: 20px;

      img.daimi {
        width: 14px;
        height: 12px;
        margin-left: 5px;
      }
    }
  }

  .inputInfoOk {
    width: 100%;
    border: 3px solid #86c6f8;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 15px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 20px;

    .inputStyle {
      border: none;
      outline: none;
      flex: 1;
      height: 100%;
      width: 100%;
      font-size: 20px;
      padding: 0 10px;
      color: #e2e5e7;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  .coinInfomation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .coinImg {
      width: 29px;
      height: 29px;

      .dailogo {
        width: 29px;
        height: 29px;
      }
    }

    .coinInfo {
      font-size: 18px;
      font-family: "Poppins", sans-serif;
      position: relative;
      top: 0px;
      font-weight: bold;
    }

    .coinInfo2 {
      font-size: 28px;
      font-family: "Poppins", sans-serif;
      position: relative;
      top: 0px;
      font-weight: bold;

      img {
        width: 20px;
        height: 20px;
        position: relative;
        left: 5px;
      }
    }
  }
}
</style>
<script>
import QrCodeGenerator from "@/components/QrCodeGenerator.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import link from "@/assets/img/link.svg";
import honor from "@/assets/img/honor.svg";
import VueClipboard from "vue-clipboard2";
//import PullRefresh from "vue-pull-refresh";
import Vue from "vue";
Vue.use(VueClipboard);
export default {
  data() {
    return {
      isBind: false,
      link: link,
      honor: honor,
      dai: dai,
      daimni: daimni,
      invAddress: "",
      daiOwnMoney: 1200.03,
      stylea: stylea,
      quer: this.$route.query.rel,
      inputContent: "Text to copy",
      copySuccess: this.$t("Share.copyTitle"),
      copySuccessText: this.$t("Share.copyText"),
    };
  },
  components: {
    // PullRefresh,
    QrCodeGenerator,
  },
  computed: {
    shortenedAddress: {
      get() {
        if (this.invAddress === "") {
          return "0x";
        } else {
          return this.invAddress.slice(0, 8) + " ••• " + this.invAddress.slice(-9);
        }
      },
      set(newVal) {
        this.invAddress = newVal;
      },
    },
    linkAddress2: {
      get() {
        return `https://flashdai.net/#/EvTeam?ref=${this.invAddress}`;
      },
    },
  },
  methods: {
    refresh(done) {
      setTimeout(() => {
        done(); // call done() to end refreshing
      }, 1500);
    },
    warning(nodesc) {
      this.$Notice.warning({
        title: nodesc ? "" : this.$t("Team.Teamstaked"),
        //desc: nodesc ? "" : this.$t("Team.Teamstaked"),
      });
    },
    success(nodesc) {
      this.$Notice.success({
        title: nodesc ? "" : this.copySuccessText,
        //desc: nodesc ? "" : this.copySuccessText,
      });
    },

    // copyText() {
    //     this.$clipboard(this.inputContent).then(
    //         function (e) {
    //             console.log(e);
    //         },
    //         function (err) {
    //             console.error('Failed to copy text: ', err);
    //         }
    //     );
    // },
    onCopy() {
      this.success(false);
      // console.log('Text successfully copied');
    },
    onError() {
      console.error("Failed to copy text");
    },
  },
  async mounted() {
    this.invAddress = await web3Utils.connectWallet();
  },
};
</script>
